import { Currencies } from 'country-to-currency';
import { proxy } from 'valtio';

export interface ExchangeRate {
  rate: number;
  symbol: string;
  currency: Currencies;
}

export const exchangeRate = proxy<ExchangeRate>({
  rate: 1,
  symbol: '₹',
  currency: 'INR',
});

interface DisplayPriceOpts {
  priceInINR: number;
  showSymbol?: boolean;
  showCurrency?: boolean;
}

export const displayPrice = ({
  priceInINR,
  showSymbol = true,
  showCurrency = false,
}: DisplayPriceOpts) => {
  if (exchangeRate.rate === 1) {
    return `${showSymbol ? '₹' : ''}${priceInINR.toLocaleString()}${showCurrency ? ' ' + 'INR' : ''}`;
  }
  const price = (priceInINR / exchangeRate.rate).toFixed(2);

  return `${showSymbol ? exchangeRate.symbol : ''}${price.toLocaleString()}${showCurrency ? ' ' + exchangeRate.currency : ''}`;
};
